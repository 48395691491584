/* Brand */
/* Brand End */
/* Primary */
/* Primary End */
/* Neutral */
/* Neutral End */
/* Status01 */
/* Status01 End */
/* Status02 */
/* Status02 End */
/* Status03 */
/* Status03 End */
/* Status04 */
/* Status04 End */
.biq-link {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.like-a-link {
  color: #006be6;
  text-decoration: none;
  cursor: pointer; }
  .like-a-link:hover, .like-a-link:visited {
    color: #6dabf2; }
  .like-a-link:active {
    color: #004799; }
