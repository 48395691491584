.avatar-name {
  width: 100%; }
  .avatar-name .biq-link {
    width: 100%; }
  .avatar-name__cell {
    display: flex;
    align-items: center;
    width: 100%; }
    .avatar-name__cell--initials {
      width: 36px;
      padding-right: 5px; }
    .avatar-name__cell--text {
      white-space: nowrap;
      display: flex;
      flex-direction: column;
      overflow: hidden; }
