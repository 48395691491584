.fiq-loading-page {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff; }
  .fiq-loading-page__full-screen {
    background-image: url(assets/images/FIQLogoBackground.dcec9a924ce074e7b063cf6df39a95a3.png);
    background-size: cover; }
