/* Brand */
/* Brand End */
/* Primary */
/* Primary End */
/* Neutral */
/* Neutral End */
/* Status01 */
/* Status01 End */
/* Status02 */
/* Status02 End */
/* Status03 */
/* Status03 End */
/* Status04 */
/* Status04 End */
.fiq-render-icon-cell {
  display: flex;
  align-items: center; }
  .fiq-render-icon-cell__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%; }
    .fiq-render-icon-cell__icon.asset-USD {
      background-color: #dff7eb;
      color: #009c4b; }
      .fiq-render-icon-cell__icon.asset-USD path {
        fill: currentColor; }
      .fiq-render-icon-cell__icon.asset-USD rect {
        fill: currentColor; }
      .fiq-render-icon-cell__icon.asset-USD circle {
        fill: currentColor; }
    .fiq-render-icon-cell__icon.asset-JPY {
      background-color: #fcf5d7;
      color: #d9af09; }
      .fiq-render-icon-cell__icon.asset-JPY path {
        fill: currentColor; }
      .fiq-render-icon-cell__icon.asset-JPY rect {
        fill: currentColor; }
      .fiq-render-icon-cell__icon.asset-JPY circle {
        fill: currentColor; }
    .fiq-render-icon-cell__icon.asset-BTC {
      background-color: #e08300;
      color: #feffff; }
      .fiq-render-icon-cell__icon.asset-BTC path {
        fill: currentColor; }
      .fiq-render-icon-cell__icon.asset-BTC rect {
        fill: currentColor; }
      .fiq-render-icon-cell__icon.asset-BTC circle {
        fill: currentColor; }
    .fiq-render-icon-cell__icon.asset-EUR {
      background-color: #e3f0ff;
      color: #006be6; }
      .fiq-render-icon-cell__icon.asset-EUR path {
        fill: currentColor; }
      .fiq-render-icon-cell__icon.asset-EUR rect {
        fill: currentColor; }
      .fiq-render-icon-cell__icon.asset-EUR circle {
        fill: currentColor; }
    .fiq-render-icon-cell__icon.asset-GBP {
      background-color: #d6cef2;
      color: #6142c7; }
      .fiq-render-icon-cell__icon.asset-GBP path {
        fill: currentColor; }
      .fiq-render-icon-cell__icon.asset-GBP rect {
        fill: currentColor; }
      .fiq-render-icon-cell__icon.asset-GBP circle {
        fill: currentColor; }
    .fiq-render-icon-cell__icon.asset-default {
      background-color: #e1e7ed;
      color: #525a66; }
      .fiq-render-icon-cell__icon.asset-default path {
        fill: currentColor; }
      .fiq-render-icon-cell__icon.asset-default rect {
        fill: currentColor; }
      .fiq-render-icon-cell__icon.asset-default circle {
        fill: currentColor; }
