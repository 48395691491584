/* Brand */
/* Brand End */
/* Primary */
/* Primary End */
/* Neutral */
/* Neutral End */
/* Status01 */
/* Status01 End */
/* Status02 */
/* Status02 End */
/* Status03 */
/* Status03 End */
/* Status04 */
/* Status04 End */
.fiq-payment-type-cell {
  display: flex;
  align-items: center; }
  .fiq-payment-type-cell__icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    width: 24px;
    height: 24px;
    margin-right: 4px; }
    .fiq-payment-type-cell__icon-wrapper.positive {
      background-color: #dff7eb; }
    .fiq-payment-type-cell__icon-wrapper.negative {
      background-color: #ffebeb; }
