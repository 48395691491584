/* Brand */
/* Brand End */
/* Primary */
/* Primary End */
/* Neutral */
/* Neutral End */
/* Status01 */
/* Status01 End */
/* Status02 */
/* Status02 End */
/* Status03 */
/* Status03 End */
/* Status04 */
/* Status04 End */
html {
  box-sizing: border-box;
  font-size: 16px; }

body,
html {
  height: 100%; }

*,
*:before,
*:after {
  box-sizing: inherit; }

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
  margin: 0;
  padding: 0;
  font-weight: normal; }

ol,
ul {
  list-style: none; }

img {
  max-width: 100%;
  height: auto; }

::-webkit-scrollbar {
  width: 6px;
  height: 6px; }

::-webkit-scrollbar-track {
  border-radius: 10px; }

::-webkit-scrollbar-thumb {
  background: #b8b8bf;
  border-radius: 10px; }

.biq-combox {
  width: 500px; }
  .biq-combox__header {
    background: #006be6; }
    .biq-combox__header svg {
      fill: #f7fafc;
      color: #f7fafc; }
  .biq-combox__title {
    color: #f7fafc; }
