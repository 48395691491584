/* Brand */
/* Brand End */
/* Primary */
/* Primary End */
/* Neutral */
/* Neutral End */
/* Status01 */
/* Status01 End */
/* Status02 */
/* Status02 End */
/* Status03 */
/* Status03 End */
/* Status04 */
/* Status04 End */
.comment-field {
  background-color: #feffff; }
  .comment-field__suggestion-el {
    display: flex;
    flex-direction: row;
    align-items: center; }
  .comment-field__control textarea {
    font-family: "Source Sans Pro", Arial, sans-serif;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    -webkit-font-smoothing: auto;
    border-radius: 0;
    box-sizing: border-box;
    border: 1px solid #e1e7ed;
    display: inline-block;
    border-radius: 3px;
    outline: none; }
    .comment-field__control textarea::placeholder {
      color: #c3cdd9;
      -webkit-font-smoothing: antialiased; }
    .comment-field__control textarea:focus {
      border: 1px solid #006be6; }
