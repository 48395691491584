/* Brand */
/* Brand End */
/* Primary */
/* Primary End */
/* Neutral */
/* Neutral End */
/* Status01 */
/* Status01 End */
/* Status02 */
/* Status02 End */
/* Status03 */
/* Status03 End */
/* Status04 */
/* Status04 End */
.fiq-render-icon {
  display: flex;
  justify-content: center;
  align-items: center; }
  .fiq-render-icon .browser {
    color: #7a8899; }
    .fiq-render-icon .browser path {
      fill: currentColor; }
    .fiq-render-icon .browser rect {
      fill: currentColor; }
    .fiq-render-icon .browser circle {
      fill: currentColor; }
